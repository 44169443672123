<template>
  <b-field v-if="contractId" label="Contract controls">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select contract action"
      expanded
    >
      <optgroup label="Contract actions">
        <option :value="[overrideContract]">Edit contract</option>
        <option
          :disabled="!['subscription'].includes(contract.provision.type)"
          :value="[transferContract]"
          >Transfer subscription</option
        >
        <option
          :disabled="
            !['subscription'].includes(contract.provision.type) &&
              [statuses.CANCELLED, statuses.ACTIVE].includes(contract.status)
          "
          :value="[upgradePlan]"
          >Upgrade subscription</option
        >
        <option
          :disabled="!['subscription'].includes(contract.provision.type)"
          :value="[changePlan]"
          >Change plan</option
        >
      </optgroup>
    </b-select>
  </b-field>
</template>

<script>
import { enums as statuses } from "@/data/contract";
export default {
  name: "StaffContractControls",
  props: {
    contractId: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      selected: null,
      statuses
    };
  },
  computed: {
    contract() {
      return this.$store.getters["billing/contract"](this.contractId) || {};
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    }
  },
  watch: {
    async selected(action) {
      if (action === null) return;
      this.processing = true;
      if (this.$_.isFunction(action[0])) await action[0](action[1]);
      this.$nextTick(() => {
        this.selected = null;
        this.processing = false;
      });
    }
  },
  created() {
    this.$store.dispatch("billing/observeContract", {
      siteId: this.contractId
    });
    this.$store
      .dispatch("sites/observeSite", { siteId: this.siteId })
      .catch(() => {
        this.siteNotFound();
      });
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveContract", {
      siteId: this.contractId
    });
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  methods: {
    transferContract() {
      const modal = this.$modal.open({
        component: () => import("@shared/contracts/_transferModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          contractId: this.contractId,
          siteId: this.siteId,
          userId: this.$_.first(this.site.owners)
        },
        events: {
          success: contractId => {
            modal.close();
            this.$nextTick(() => {
              this.$router.push({
                path: `/admin/contracts/${contractId}`
              });
            });
          }
        }
      });
    },
    upgradePlan() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradePlanModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          contract: this.contract
        },
        events: {
          updated: () => {
            this.$emit("updated", this.contract);
          }
        }
      });
    },
    changePlan() {
      this.$modal.open({
        component: () => import("@shared/contracts/_selectPlanModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.contract.site.ref.id,
          contractId: this.contractId
        }
      });
    },
    overrideContract() {
      this.$modal.open({
        component: () => import("@shared/contracts/_overrideContractModal"),
        parent: this,
        width: 520,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.contractId
        }
      });
    },
    siteNotFound() {
      this.$router.push(`/admin/sites`);
      this.$toast.open({
        message: `Invalid permissions or site not found`,
        type: "is-danger"
      });
    }
  }
};
</script>
